.logoSpin {
  animation: spin 0.88s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(540deg);
  }
}
