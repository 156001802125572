.formContainer {
  text-align: center;
  margin-top: 20px;
}

.formSection {
  position: relative;
  margin-bottom: 20px;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
  background-color: #ffffff;
}

.formField {
  margin-bottom: 22px;
}

@media only screen and (max-width: 599px) {
  .formContainer {
    margin: 0;
  }

  .formSection {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }
}

.container {
	font-weight: 400;
}

.actionRow {
	margin-bottom: 15px;
}

.sectionHeader {
	color: #152939;
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
}

.sectionHeaderHint {
	font-size: 16px;
	font-weight: 400;
	margin-top: 4px;
}

.sectionBody {
	margin-bottom: 30px;
}

.signInButton {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  box-sizing: content-box;
}

.signInButtonIcon {
  position: absolute;
  left: 0;
}

.signInButtonContent {
  text-align: center;
  display: block;
  padding: 18px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.input {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  color: #152939;
  background-color: #fff;
  background-image: none;
  border: 1px solid  #c4c4c4;;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.input:disabled {
  color: #545454;
  background-color: #e1e4ea;
}

.inputLabel {
  color: #152939;
  font-size: 14px;
  margin-bottom: 8px;
}

.label {
  color:  #152939;
}

.radio {
  margin-right: 18px;
  vertical-align: bottom;
}

@media only screen and (max-width: 599px) {
  .input {
    font-size: 16px;
  }
}
