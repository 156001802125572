@import '../node_modules/typeface-poppins/index.css';

body { 
  background-color:  #F6F9FC!important;
  font-family: "Poppins", Helvetica, Arial, sans-serif!important;
}

button {
  background: #492660!important;
  border-color: #492660!important;
}

a {
  color: #492660!important;
}

[class^="Form"] {
  margin-top: 0px;
  margin-bottom: 10px;
}


html, body, .app {
  height: 100%;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
